import {mapActions, mapGetters} from "vuex";
import product from '../../../../components/product/index.vue'
export default {
  name: "profile-favorites",
  components:{
    product
  },
  computed:{
    ...mapGetters({
      favourites: 'favorites/whichList',
    }),
    favoritesProducts(){
      let arr = []
      for (let i in this.favourites){
        arr.push(this.favourites[i].product.data)
      }
      console.log(arr)
      return arr
    }
  },
  methods:{
    ...mapActions({

    })
  }
}